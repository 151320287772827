import { gql } from '@apollo/client';

export const STATION_MAP_QUERY = gql`
  query stationMapQuery($systemId: ID) {
    stationMapPage: sanityStationMapPage {
      title
      heading
    }
    system(systemId: $systemId) {
      id
      inSeason
      coords {
        lat
        lng
      }
    }
  }
`;

export const FEATURE_TOGGLES_QUERY = gql`
  query featureTogglesQuery($systemId: ID) {
    featureToggles(systemId: $systemId) {
      freeFloatingEnabled
    }
  }
`;

export const DOCK_GROUPS_QUERY = gql`
  query DockGroups($systemId: ID) {
    dockGroups(systemId: $systemId) {
      id
      name
      title
      state
      subTitle
      enabled
      availabilityInfo {
        availableVehicles
        availableDocks
        availableVirtualDocks
        availablePhysicalDocks
        availableVehicleCategories {
          category
          count
        }
      }
      coord {
        lat
        lng
      }
    }
  }
`;

export const FREE_FLOATING_VEHICLES_QUERY = gql`
  query FreeFloatingVehicles($systemId: ID!) {
    freeFloatingVehicles(systemId: $systemId) {
      id
      name
      number
      batteryCharge
      currentRange
      maxRange
      category
      available
      location {
        location {
          lat
          lng
        }
      }
    }
  }
`;

export const PROHIBITED_ZONES_QUERY = gql`
  query ProhibitedZones($systemId: ID!) {
    areas(systemId: $systemId) {
      id
      fillColour
      strokeColour
      polygon {
        lat
        lng
      }
      configurations {
        ... on AreaTripEnd {
          type
          price
          schedules {
            startTime
            endTime
            allHours
            startDate
            endDate
            daysOfWeek
          }
          graceSeconds
        }
      }
    }
  }
`;
