import { useStyledTheme } from '../lib/hooks';
import { useQuery } from '@apollo/client';
import { Flex } from '@urbaninfrastructure/react-ui-kit';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loading, ViewTypes, DEFAULT_VIEW } from '../components';
import {
  stationMapQuery,
  stationMapQueryVariables,
  settings
} from '../core-types';
import { bootstrap } from '../lib/bootstrap';
import { SETTINGS_QUERY } from '../lib/core/SettingsQuery';
import { CustomNextPage } from '../lib/types';
import { isServer } from '../lib/util';
import { getQueryString } from '../lib/utils';
import Layout from '../modules/Layout';
import MapboxCss from '../modules/StationMap/MapboxCss';
import { STATION_MAP_QUERY } from '../modules/StationMap/graphql';

const MAP_HEIGHT = '80vh';
const DEFAULT_MAP_TYPE = 'dynamic';
function LoadingStationMap() {
  return (
    <Flex
      width={1}
      alignItems="center"
      justifyContent="center"
      height={MAP_HEIGHT}
    >
      <Loading />
    </Flex>
  );
}
const StationMap = dynamic(import('../modules/StationMap'), {
  ssr: false,
  loading: LoadingStationMap
});
function getViewType(view: string | undefined): ViewTypes | undefined {
  switch (view) {
    case 'parking':
    case 'live':
    case 'bikes': {
      return view;
    }
    default: {
      return;
    }
  }
}
const StationMapPage: CustomNextPage<{
  view?: ViewTypes;
  statusCode?: number;
}> = props => {
  const { url, statusCode, view = DEFAULT_VIEW } = props;
  const theme = useStyledTheme();

  const mapType = useMemo(() => {
    const mapTypeRaw = getQueryString(url.query.mapType);
    switch (mapTypeRaw) {
      case 'static':
      case 'dynamic': {
        return mapTypeRaw;
      }
      default: {
        return DEFAULT_MAP_TYPE;
      }
    }
  }, [url.query.mapType]);
  const { loading, data, error } = useQuery<
    stationMapQuery,
    stationMapQueryVariables
  >(STATION_MAP_QUERY);
  const title = data && data.stationMapPage && data.stationMapPage.title;

  const isSmallScreen =
    !isServer &&
    window.matchMedia(`(max-width: ${theme.breakpoints[1]})`).matches;
  return (
    <>
      <MapboxCss />
      <Layout
        statusCode={statusCode}
        title={title}
        loading={false}
        url={url}
        error={
          error ? (
            <FormattedMessage
              id="pages.station-map.error"
              defaultMessage="Could not load stations"
            />
          ) : null
        }
        hideIntercomLauncher={isSmallScreen}
        contentProps={{
          display: 'flex',
          position: 'relative',
          // Override Content defaultProps
          py: 0,
          mx: 0,
          mb: 0
        }}
        render={() => {
          if (loading) {
            return <LoadingStationMap />;
          }
          if (!data || !data.stationMapPage || !view) {
            return null;
          }
          const enableAnimatedTrips = view === 'live';
          return (
            <StationMap
              {...props}
              view={view}
              mapType={mapType}
              enableAnimatedTrips={enableAnimatedTrips}
              mapHeight={MAP_HEIGHT}
            />
          );
        }}
      />
    </>
  );
};

StationMapPage.getInitialProps = async ctx => {
  const [stationMapQueryResult, settingsQueryResult] = await Promise.all([
    ctx.apolloClient.query<stationMapQuery, stationMapQueryVariables>({
      query: STATION_MAP_QUERY
    }),
    ctx.apolloClient.query<settings>({
      query: SETTINGS_QUERY
    })
  ]);
  const GBFS =
    settingsQueryResult.data &&
    settingsQueryResult.data.system &&
    settingsQueryResult.data.system.GBFS;
  const view = getViewType(getQueryString(ctx.query.view)) || DEFAULT_VIEW;
  let statusCode;
  if (
    (!stationMapQueryResult.errors &&
      !stationMapQueryResult.data?.stationMapPage) ||
    // If view is not set correctly, or set to live when GBFS flag is set to false, throw 404
    !view ||
    (view === 'live' && !GBFS)
  ) {
    statusCode = 404;
    if (ctx.res) {
      // eslint-disable-next-line require-atomic-updates
      ctx.res.statusCode = statusCode;
    }
  }
  return {
    statusCode,
    view
  };
};
export default bootstrap(StationMapPage);
